import { makeStyles } from '@material-ui/styles'
import { H1, Text } from '@system'
import React from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles((theme) => {
  return {
    stat: {
      color: theme.palette.text.iris,
      display: 'block',
      fontWeight: theme.typography.fontWeightLight,
      margin: '0 auto',
      textAlign: 'center',
      width: '75%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    subtitle: {
      color: theme.palette.secondary.main,
      display: 'block',
      flex: '1 0 auto',
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      padding: '8px 16px 24px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '8px 16px 16px',
      },
    },
  }
})

const StatWithSubtitle = (props) => {
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <H1 className={classes.stat} component="h2">
        {props.blok.stat}
      </H1>
      <Text className={classes.subtitle}>{props.blok.subtitle}</Text>
    </SbEditable>
  )
}

export default StatWithSubtitle
